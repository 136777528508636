<template>
  <div class="row">
    <div class="col-12">
      <card>
        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Volunteers List</h4>
          <div class="d-flex justify-content-start">
            <div class="text-center pt-1 px-1">
              <a v-if="$store.getters['auth/checkAccess']('ar/volunteers/export')"
                 class="btn btn-info btn-wd export_excel" style="display:flex;"
                 @click="openModal('basic')"> Export to excel
                <span class="btn-label px-2">
                  <i class="nc-icon nc-cloud-download-93" style="font-size:20px;"></i>
                  </span>
              </a>
            </div>
          </div>
        </div>
        <div>
          <general-data-table
            ref="table"
            :lang="lang"
            :key="componentKey"
            @reorder="reorder($event)"
            :row-key="'id'"
            :api-url="'volunteers/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :prop="column.value"
                             :sortable="column.sortable"
                             :label="column.label">
            </el-table-column>
            <el-table-column
              :min-width="120"
              fixed="right"
              align="center"
              label="Actions">
              <template slot-scope="scope">

                <template>
<!--                  <router-link v-if="$store.getters['auth/haveOneOfPermissions'](['ar/volunteers/update']) ||-->
<!--                                     $store.getters['auth/checkAccessOwner']('restricted_own/ar/volunteers/update', scope.row.creator_id)"-->
<!--                               v-tooltip.top-center="'Edit'"-->
<!--                               class="btn-warning btn-simple btn-link"-->
<!--                               :to="'/volunteers/edit/'+scope.row.id+'/'+lang">-->
<!--                    <i class="fa fa-edit"></i>-->
<!--                  </router-link>-->
                  <!--                  <router-link  v-if="$store.getters['auth/haveOneOfPermissions'](['election_activities_ar_logs_view'])"-->
                  <!--                                v-tooltip.top-center="'Log'"-->
                  <!--                                class="btn-info btn-simple btn-link"-->
                  <!--                                :to="'/logs/election_activities_' + lang+'_'+scope.row.id"><i class="fa fa-eye"></i></router-link>-->

                  <a v-if="$store.getters['auth/haveOneOfPermissions'](['ar/volunteers/delete'])"
                     v-tooltip.top-center="'Delete'"
                     class="btn-danger btn-simple btn-link"
                     @click="openDeleteModal(scope.row.id)">
                    <i class="fa fa-times"></i></a>
                </template>


              </template>
            </el-table-column>
          </general-data-table>
        </div>
      </card>
      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this Volunteers?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>
      <export-modal id="export-modal" title="Volunteers" url="volunteers/export"
                    :filters="exportFilters" :modals="modals"></export-modal>
    </div>
  </div>
</template>

<script>
import {DeleteModal as DeleteModal, Switch as LSwitch} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import {TableColumn} from "element-ui";
import FgSelect from "@/components/Inputs/formGroupSelect";
import ExportModal from '@/components/ExportModal/ExportModal.vue';


export default {
  components: {
    GeneralDataTable,
    DeleteModal,
    [TableColumn.name]: TableColumn,
    LSwitch,
    FgSelect,
    ExportModal

  },

  data() {
    return {
      tableColumns: [
        {label: 'Volunteers Title', value: 'name', minWidth: '200', align: 'center'},
        {label: 'Volunteers Phone', value: 'phone', minWidth: '200', align: 'center'},
        {label: 'Volunteers Email', value: 'email', minWidth: '200', align: 'center'},
        {label: 'Volunteers Location', value: 'election_location', minWidth: '200', align: 'center'},
        {label: 'Location Number', value: 'location_number', minWidth: '200', align: 'center'},
        {label: 'Record Number', value: 'record_number', minWidth: '200', align: 'center'},
      ],
      modals: {
        basic: false,
        centered: false,
        custom: false,
        confirm: false
      },
      deleteModalVisibility: false,
      toDeleteId: undefined,
      lang: 'ar',
      componentKey: 0,
      exportFilters: [
        {
          key: 'created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'gt',
          label: 'from',
          class: 'col-6',
          value: null,
        },
        {
          key: 'created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'lt',
          label: 'to',
          class: 'col-6',
          value: null,
        },
        {
          key: 'email',
          type: 'text',
          frontType:'simple',
          label: 'Email',
          class: 'col-6',
          value: null,
        },
        {
          key: 'name',
          type: 'text',
          frontType:'simple',
          label: 'Name',
          class: 'col-6',
          value: null,
        },
        {
          key: 'phone',
          type: 'text',
          frontType:'simple',
          label: 'Phone',
          class: 'col-6',
          value: null,
        },
        {
          key: 'election_location',
          type: 'text',
          frontType:'simple',
          label: 'Election Location',
          class: 'col-6',
          value: null,
        },
      ],
    }
  },
  created(){
    const path = window.location.href;
    this.lang = path.substring(path.lastIndexOf('/') + 1)
  },
  methods: {
    handleActiveStatus(row) {
      let data = {
        'is_active': row.is_active,
        'lang': this.lang,
      }
      this.axios.post("volunteers/change-active-status/" + row.id, data).then((response) => {
        this.$notify({
          message: "Volunteers updated successfully",
          timeout: 1000,
          type: 'success'
        });
      }).catch((error) => {
        console.log(error)
      })
    },
    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },

    async confirmDeleteModal() {
      try {
        let data = {
          'id': this.toDeleteId,
          'lang': this.lang,
        }
        await this.axios.delete("volunteers/delete", {headers: {}, data});
        this.$refs.table.getData("updateData");
        this.$notify({
          message: "Volunteers deleted successfully",
          timeout: 1000,
          type: 'success'
        });
      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      } finally {
        this.closeDeleteModal();
        this.toDeleteId = undefined;
      }
    },
    applyFilter() {
      this.$refs.table.getData("updateData");
    },
    async reorder(data) {
      try {
        let body = {};
        let orderData = [];
        data.forEach((row, i) => {
          orderData.push({"id": row.id, "sort_order": i + 1})
        });
        body = {
          lang: this.lang,
          data: orderData,
        }

        await this.axios.post("volunteers/re-order", body);

        this.$notify({
          message: "order updated successfully",
          timeout: 1000,
          type: 'success'
        });

      } catch (error) {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      }
    },
    openModal (name) {
      this.modals[name] = true
    },
    closeModal (name) {
      this.modals[name] = false
    },
  },
}
</script>

